import dayjs from "dayjs";
import React from "react";

const Mineral = () => {
  return (
    <>
      <p align="center">
        <strong>1. Умумий қоидалар</strong>
      </p>
      <p>
        1.1. Ўзбекистон Республикаси Фуқаролик кодексининг 370-моддасига мувофиқ
        агар қуйида кўрсатилган шартлар қабул қилиниб, хизматлар учун тўлов
        амалга оширилса, қабул қилувчи юридик ёки жисмоний шахс Ариза берувчига
        айланади.
      </p>
      <p>
        1.2. Оферта шартномасини тўлиқ ва сўзсиз қабул қилиш (таклиф
        маъқулланиш) пайтида Агентлик томонидан Ариза берувчидан
        <strong>
          Минерал ўғитларни ва ўсимликларни ҳимоя қилиш учун фойдаланиладиган
          кимёвий воситаларни олиб кириш учун рухсатнома
        </strong>
        сини олиш учун ариза (кейинги ўринларда - ариза) электрон ҳужжат
        кўринишида қабул қилинади ва
        <strong>
          Минерал ўғитларни ва ўсимликларни ҳимоя қилиш учун фойдаланиладиган
          кимёвий воситаларни олиб кириш учун рухсатнома
        </strong>
        сини (кейинги ўринларда -Рухсатнома) олиш тўғрисидаги ариза кўриб
        чиқилганлиги учун йиғим ундирилади.
      </p>
      <p>
        1.3. «Электрон ҳужжат айланиши тўғрисида»ги Ўзбекистон Республикаси
        Қонунига мувофиқ электрон ҳужжат шаклидаги ариза коғоз ҳужжатга тенг
        бўлиб, Вазирлар Маҳкамасининг 2024 йил 5 августдаги «Ўсимликларни ҳимоя
        қилиш соҳасидаги лицензиялаш ва рухсат этиш хусусиятига эга ҳужжатларни
        бериш билан боғлиқ айрим норматив-ҳуқуқий ҳужжатларни тасдиқлаш
        тўғрисида»ги № 481-сонли карори билан тасдикланган Низомнинг 3-иловаси
        5-бандига мувофиқ маълумотлар батафсил баён этилиши, шунингдек Ариза
        берувчининг электрон ракамли имзоси билан тасдиқланган бўлиши лозим.
      </p>
      <p>
        1.4 Ариза берувчи Оферта шартномаси (кейинги ўринларда - Шартнома)
        шартлари билан танишиб чиққач,
        <a href="http://www.singlewindow.uz">http://www.singlewindow.uz</a>.
        манзили бўйича рухсатномани олиш учун электрон ариза юборади. Ариза
        берувчи Шартномада белгиланган тўлов микдорини тўлагандан сўнг унинг
        аризаси фаоллаштирилади.
      </p>
      <p>
        1.5. Ариза берувчи Шартномани белгиланган тартибда қабул қилиб, шартнома
        матнида келтириб ўтилган шаклдаги барча шартлар билан танишиб
        чиққанлигини, рози бўлганлигини ҳамда тўлиқ ва сўзсиз қабул қилганлигини
        тасдиқлайди.
      </p>
      <p>
        1.6. Ариза берувчи ушбу ҳужжатнинг 2-бандида кўрсатилган тартибда
        Шартномани қабул қилади ва унда белгиланган шартлар асосида Шартнома
        тузилишига розилик билдиради.
      </p>
      <p>
        1.7. Рухсатномани бериш ёки беришни рад этиш тўғрисидаги қарор
        Агентликка ариза келиб тушган кундан бошлаб беш иш куни давомида қабул
        қилинади ва қарорга мувофиқ тегишли ҳужжат ҳақида талабгорга орқали
        <a href="http://www.singlewindow.uz">http://www.singlewindow.uz</a>.
        манзили бўйича электрон шаклда хабар берилади.
      </p>
      <p>1.8. Шартнома чақириб олинмайди.</p>
      <p>
        1.9. Ўзбекистон Республикаси Фукаролик кодексининг 367-375-моддалари ва
        «Электрон ҳужжат айланиши тўғрисида»ги, «Электрон ракамли имзо
        тўғрисида»ги Ўзбекистон Республикаси Қонунларига мувофик Шартномада
        Ариза берувчининг муҳри ва / ёки имзоси талаб этилмайди хамда шунга
        асосан ўз юридик кучини сақлаб колади.
      </p>
      <p>
        <a name="bookmark3">2. Ш</a>артнома предмети
      </p>
      <p>
        2.1. Ўзбекистон Республикаси 2024 йил 5 августдаги «Ўсимликларни ҳимоя
        қилиш соҳасидаги лицензиялаш ва рухсат этиш хусусиятига эга ҳужжатларни
        бериш билан боғлиқ айрим норматив-ҳуқуқий ҳужжатларни тасдиқлаш
        тўғрисида»ги № 481-сонли карорига мувофиқ Агентлик аризани кўриб чиқиши
        учун Ариза берувчи қуйидаги тартибда йиғим суммасини тўлайди:
      </p>
      <div align="center">
        <table border="1" cellspacing="0" cellpadding="0" width="656">
          <tbody>
            <tr>
              <td width="37">
                <p align="center">Т/р</p>
              </td>
              <td width="308">
                <p align="center">Хизмат тури</p>
              </td>
              <td width="311" valign="top">
                <p align="center">Бир бирлик учун йиғим суммаси</p>
              </td>
            </tr>
            <tr>
              <td width="37">
                <p>1.</p>
              </td>
              <td width="308" valign="bottom">
                <p align="center">
                  Минерал ўғитларни ва ўсимликларни ҳимоя қилиш учун
                  фойдаланиладиган кимёвий воситаларни олиб кириш учун аризани
                  кўриб чиқиш.
                </p>
              </td>
              <td width="311" valign="top">
                <p align="center">
                  Базавий ҳисоблаш миқдорининг 1 (бир) баравари.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table
          cellspacing="0"
          cellpadding="0"
          hspace="0"
          vspace="0"
          width="661"
          height="102"
          align="center"
        >
          <tbody>
            <tr>
              <td valign="top" align="left" height="77"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <br clear="ALL" />
      <p>
        2.2. Тўлов тартиби: Минерал ўғитларни ва ўсимликларни ҳимоя қилиш учун
        фойдаланиладиган кимёвий воситаларни олиб кириш учун тўлов, Агентликка
        ариза келиб тушган кундан бошлаб 5 (беш) иш куни давомида 100% миқдорда
        амалга оширилади сўнг Агентлик томонидан рухсатнома тақдим қилинади.
      </p>
      <p>
        2.3. Агар йиғим ариза юборилган кундан бошлаб 5 (беш) иш куни ичида
        тўланмаса, буюртмачининг аризаси бекор килинади ва уни кабул килиш
        амалга оширилмаган ҳисобланади.
      </p>
      <p>
        2.4. Рухсатномани қайта расмийлаштириш учун ариза берувчиларнинг
        рухсатномани бериш тўғрисидаги аризаси Агентлик томонидан кўриб
        чиқилганда ва рухсатнома қайта расмийлаштириб берилганда ушбу шартнома
        2.1.-бандида назарда тутилган йиғим суммасининг ярми ундирилади.
      </p>
      <p>
        <a name="bookmark4">3. Т</a>арафларнинг мажбуриятлари
      </p>
      <p>
        3.1. Ариза берувчи Минерал ўғитларни ва ўсимликларни ҳимоя қилиш учун
        фойдаланиладиган кимёвий воситаларни Ўзбекистон Республикасига олиб
        кириш режалаштирилган кундан камида 30 кун аввал Агентлик такдим килиш
        мажбуриятини олади.
      </p>
      <p>
        3.2. Агентлик аризани кўриб чикиб, рухсатномани расмийлаштириш ёки
        беришни рад этиш тўғрисида карорни ариза олинган кундан бошлаб 5 иш куни
        ичида ёзма равишда хабардор қилади. Ариза берувчи аризасидан воз кечган
        (қайтариб олинган) ёки ариза кўриб чиқилгандан сўнг рухсатномани бериш
        рад этилган тақдирда тўланган йиғим суммаси қайтарилмайди.
      </p>
      <p>
        Рухсатномани беришни рад этиш асослари 2024 йил 5 августдаги
        «Ўсимликларни ҳимоя қилиш соҳасидаги лицензиялаш ва рухсат этиш
        хусусиятига эга ҳужжатларни бериш билан боғлиқ айрим норматив-ҳуқуқий
        ҳужжатларни тасдиқлаш тўғрисида»ги № 481-сонли карорида белгиланган.
      </p>
      <p>
        3.3. Рад этиш тўғрисидаги хабарнома ариза берувчига ёзма шаклда, рад
        этиш сабаблари, конун ҳужжатларининг аник нормалари ва ариза берувчи
        кўрсатилган сабабларни бартараф этиб, такроран кўриб чикиш учун
        ҳужжатларни тақдим этиши мумкин бўлган муддат кўрсатилган ҳолда{" "}
        <a href="http://www.singlewindow.uz">http://www.singlewindow.uz</a>{" "}
        ахборот тизими орқали юборилади. Ариза берувчи рад этиш сабабларини
        бартараф этиб ва ҳужжатларни такроран кўриб чиқиш учун тақдим этиши
        мумкин бўлган муддат рухсатномани бериш рад этилганлиги ҳақидаги ёзма
        хабарнома олинган кундан эътиборан ўн иш кунидан кам бўлмаслиги керак.
      </p>
      <p>
        Ариза берувчи рухсатномани беришни рад этиш учун асос бўлган сабабларни
        белгиланган муддатда бартараф этган тақдирда, ҳужжатлар такроран кўриб
        чиқиш, рухсатномани бериш ёки уни беришни рад этиш ариза берувчидан рад
        этиш сабаблари бартараф этилганлигини тасдиқловчи тегишли ҳужжатлар
        олинган кундан эътиборан беш иш куни ичида такроран кўриб чиқилади.
        Ариза такроран кўриб чиқилганлиги учун йиғим ундирилмайди.
      </p>
      <p>
        3.4. Агентлик аризадаги маълумотларни ошкор қилмаслик мажбуриятини
        олади. Амалдаги қонунчиликка мувофиқ асосли равишда маълумотни ошкор
        қилиш ҳолатларида мажбурият бузилган деб ҳисобланмайди.
      </p>
      <p align="center">
        <strong>4.</strong> <strong>Коррупцияга қарши қўшимча шартлар</strong>
      </p>
      <p>
        4.1. Тарафлар оферта тузишда, офертанинг амал қилиш муддатида ва ушбу
        муддат тугаганидан сўнг, оферта билан боғлиқ коррупциявий ҳаракатлар
        содир қилмасликка келишиб оладилар.
      </p>
      <p>
        4.2. Тарафлар Адлия вазирлиги, Коррупцияга қарши курашиш агентлиги,
        Савдо-саноат палатасининг 2021 йил 12 ноябрдаги 1/4671, 01-19/7284,
        7/01-08-9544-сон қарорига илова билан тасдиқланган “Хўжалик юритувчи
        субъектлар томонидан шартномалар тузишда қўллаш учун тавсивий характерга
        эга бўлган коррупцияга қарши қўшимча шартлар” (кейинги ўринларда -
        коррупцияга қарши қўшимча шартлар)га мувофиқ коррупциянинг олдини олиш
        чораларини тан олади ва уларга риоя этилиши бўйича ҳамкорликни
        таъминлайдилар.
      </p>
      <p>
        4.3. Ҳар бир тараф оферта тузилган пайтда бевосита ўзи ёки унинг ижроия
        органлари, мансабдор шахслари ва ходимлари томонидан шартнома билан
        боғлиқ муносабатлар юзасидан қонунга хилоф равишда пул, моддий
        қийматликлар берилмаганлигини, шартнома тузилиши эвазига норасмий пул
        ёки бошқа моддий қийматликлар олинишига йўл қўйилмаганлигини, таклиф
        этилмаганлигини, уларни беришга ваъда қилинмаганлигини, шунингдек моддий
        ёки ҳар қандай турдаги имтиёз, устунликлар олинмаганлигини (келажакда бу
        турдаги ҳаракатларни амалга ошириши мумкинлиги ҳақида таассурот
        қолдирилмаганлигини) кафолатлайди.
      </p>
      <p>
        Тарафлар, улар томонидан шартнома доирасида жалб қилинган шахсларнинг
        (ёрдамчи пудратчи ташкилотлар, агентлар ва тарафлар назорати остидаги
        бошқа шахсларнинг) юқоридаги ҳаракатларни содир этмаслиги юзасидан
        оқилона чоралар кўради.
      </p>
      <p>
        4.4. Тарафлар уларнинг назорати остида бўлган ва улар номидан ҳаракат
        қиладиган шахслар томонидан коррупцияга қарши белгиланган мажбуриятлар
        бузилганлиги ҳолатлари ҳақида хабар берилганлиги учун уларга тазйиқ
        ўтказилмаслигини кафолатлайдилар.
      </p>
      <p>
        4.5. Агар бир тарафга бошқа тарафнинг коррупцияга қарши қўшимча
        шартларнинг 4 ва 5-бандларида белгиланган мажбуриятларнинг бузилишига
        йўл қўйилганлиги маълум бўлиб қолса, иккинчи тарафни бу ҳақда зудлик
        билан хабардор қилиши ва ушбу тарафдан оқилона муддат ичида тегишли
        чоралар кўрилишини ва амалга оширилган ишлар юзасидан унга хабардор
        қилишини талаб қилиши шарт. Тарафнинг талаби бўйича иккинчи тараф
        томонидан қоидабузарликларни бартараф қилиш бўйича оқилона муддат ичида
        тегишли чоралар кўрилмаган ёки кўриб чиқиш натижалари ҳақида хабардор
        қилмаган тақдирда, ушбу тараф зарарни тўлиқ қоплаб беришни талаб қилишга
        ҳақли.
      </p>
      <p>5. Жавобгарлик, низоларни ҳал этиш тартиби</p>
      <p>
        5.1.Тарафлар Ўзбекистон Республикаси қонунчилигига мувофиқ жавобгар
        бўладилар.
      </p>
      <p>
        5.2. Шартномани бажариш вақтида форс-мажор ҳолатлари: давлат ҳокимияти
        органларининг ҳаракатлари, ёнғин, сув тошқини, зилзила, бошқа табиий
        офатлар, электр энергиясининг йўқлиги, иш ташлашлар, фуқаролик урушлари,
        тартибсизликлар, шунингдек юқорида санаб ўтилмаган ҳолатлар билан
        чекланмаган ҳар қандай бошқа фавқулодда ҳодисалар юзага келиб, Шартнома
        шартлари бузилса, Агентлик жавобгарликдан озод этилади.
      </p>
      <p>5.3. Агентлик қуйидагилар учун жавобгар эмас:</p>
      <p>
        - Aриза берувчига ўз хизматларидан фойдаланишни таъминлайдиган умумий
        алоқа каналлари ёки хизматларининг сифати учун;
      </p>
      <p>
        - Ариза берувчи томонидан http://www. singlewindow.uz. сайтида оммавий
        тарзда тақдим этилган маълумотлар учун.
      </p>
      <p>
        5.4. Ариза берувчининг Агентлик томонидан кўрсатилган хизматларга
        эътирози ёки шикоятлари{" "}
        <a href="mailto:info@karantin.uz">info@karantin.uz</a>, электрон почтаси
        орқали кўриб чиқиш учун қабул қилинади.
      </p>
      <p>
        5.5. Низоли вазиятларни кўриб чиқишда Агентлик Ариза берувчидан низога
        доир барча тегишли ҳужжатларни талаб қилишга ҳақли.
      </p>
      <p>
        5.6. Тарафлар низо ва келишмовчиликлар юзага келганда судгача низоларни
        ҳал этиш тартибини қўллаш мажбуриятини оладилар. Агар низони судгача ҳал
        қилишнинг имкони бўлмаса, тарафлар судга мурожаат қилиш ҳуқуқига
        эгадирлар.
      </p>
      <p align="center">
        <strong>6. Алоҳида шартлар</strong>
      </p>
      <p>
        6.1. Ариза берувчи Шартномани қабул қилиш ҳамда бажариш учун зарур
        бўлган барча хуқуқ ва ваколатларга эга.
      </p>
      <p>
        6.2.Агентлик ушбу Шартноманинг исталган шартларини ўзгартириш ёки
        тўлдириш ҳуқуқини ўз веб-сайтида барча ўзгаришларни эълон қилиш орқали
        сақлаб қолади.
      </p>
      <p>
        6.3. Ушбу Шартномада назарда тутилмаган барча масалалар Ўзбекистон
        Республикаси қонунчилиги меъёрлари асосида тартибга солинади.
      </p>
      <p>
        6.5 Ушбу Шартномани амал килиш муддати {dayjs().format("YYYY")} йил 31
        декабрь.
      </p>
    </>
  );
};

export default Mineral;

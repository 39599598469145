import { requestReg } from "../helpers/createRequest";

export const getSubstances = async () => {
  return await requestReg("product/substances");
};
export const postSubstance = async (data) => {
  return await requestReg.post("product/substances", data);
};
export const getTypes = async (params) => {
  return await requestReg("product/types", { params });
};
export const getCrops = async () => {
  return await requestReg("item/crops");
};
export const getShapes = async () => {
  return await requestReg("item/shapes");
};
export const getManufacturers = async (params) => {
  return await requestReg("product/manufacturers", { params });
};

export const applicationCreate = async (data) => {
  return await requestReg.post("product/application/create", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const applicationUpdate = async (data) => {
  return await requestReg.patch("product/application/create", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const MineralInnRefresh = async (data) => {
  return await requestReg.post("product/company/refresh", data);
};

export const applicationDelete = async (text, id) => {
  return await requestReg.put(`product/application/create?app_id=${id}`, {
    is_active: false,
    user_cancel_reason: text,
  });
};

export const getList = async (params) => {
  return await requestReg("product/application/list", { params });
};

import dayjs from "dayjs";
import React from "react";

const Korik = () => {
  return (
    <>
      <p>
        <br />
        1. Умумий қоидалар
      </p>
      <p>
        ​1.1.Ўзбекистон Республикаси Фуқаролик кодексининг 370-моддасига мувофиқ
        агар қуйида кўрсатилган шартлар қабул қилиниб, хизматлар учун тўлов
        амалга оширилса, қабул қилувчи юридик ёки жисмоний шахс Ариза берувчига
        айланади.
      </p>
      <p>
        ​1.2.Ариза берувчи томонидан Оферта шартномасини тўлиқ ва сўзсиз қабул
        қилиш (таклиф маъқулланиш) пайтида Агентлик томонидан Ариза берувчидан
        минерал ўғитлар ва/ёки кимёвий воситаларни иккиламчи кўрикдан ўтказиш
        бўйича юборган электрон мурожаати (кейинги ўринларда - ариза) электрон
        ҳужжат кўринишида қабул қилинади ҳамда юкни иккиламчи кўрикдан ўтказиш
        учун йиғим ундирилади.
      </p>
      <p>
        1.3. «Электрон ҳужжат айланиши тўғрисида»ги Ўзбекистон Республикаси
        Қонунига мувофик электрон ҳужжат шаклидаги ариза коғоз ҳужжатга тенг
        бўлиб, Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2024 йил 5
        августдаги 481-сонли карори билан тасдикланган «Минерал ўғитларни ва
        ўсимликларни ҳимоя қилиш учун фойдаланиладиган кимёвий воситаларни
        Ўзбекистон Республикасига олиб киришда кўрикдан ўтказиш, ўрганиш ва
        таҳлил қилиш тартиби тўғрисидаги Низом»нинг 2-иловасига мувофиқ
        маълумотлар батафсил баён этилиши, шунингдек Ариза берувчининг электрон
        рақамли имзоси билан тасдиқланган бўлиши лозим.
      </p>
      <p>
        1.4 Ариза берувчи Оферта шартномаси (кейинги ўринларда - Шартнома)
        шартлари билан танишиб чиққач, http://www.singlewindow.uz. манзили
        бўйича иккиламчи кўрикдан ўтказиш бўйича электрон ариза юборади. Ариза
        берувчи Шартномада белгиланган тўлов микдорини тўлагандан сўнг унинг
        аризаси фаоллаштирилади.
      </p>
      <p>
        ​1.5.Ариза берувчи Шартномани белгиланган тартибда қабул қилиб, шартнома
        матнида келтириб ўтилган шаклдаги барча шартлар билан танишиб
        чиққанлигини, рози бўлганлигини ҳамда тўлиқ ва сўзсиз қабул қилганлигини
        тасдиқлайди.
      </p>
      <p>
        ​1.6.Ариза берувчи Ушбу шартномани қабул қилади ва унда белгиланган
        шартлар асосида Шартнома тузилишига розилик билдиради.
      </p>
      <p>
        ​1.7. Ариза берувчининг минерал ўғитлар ва/ёки кимёвий воситаларни
        иккиламчи кўрикдан ўтказиш бўйича юборган аризаси Агентлик томонидан уч
        иш куни ичида кўриб чиқилади (юк иккиламчи кўрикдан ўтказилади) ҳамда
        кўрик далолатномаси расмийлаштириб берилади.
      </p>
      <p>​1.8. Шартнома чақириб олинмайди.</p>
      <p>
        ​1.9. Ўзбекистон Республикаси Фуқаролик кодексининг 367-375-моддалари ва
        «Электрон ҳужжат айланиши тўғрисида»ги, «Электрон рақамли имзо
        тўғрисида»ги Ўзбекистон Республикаси Қонунларига мувофиқ Шартномада
        Аризачининг муҳри ва / ёки имзоси талаб этилмайди ҳамда шунга асосан ўз
        юридик кучини сақлаб қолади.
      </p>
      <p>2. Шартнома предмети</p>
      <p>
        ​2.1. Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2024 йил 5
        августдаги 481-сонли карори билан тасдикланган «Минерал ўғитларни ва
        ўсимликларни ҳимоя қилиш учун фойдаланиладиган кимёвий воситаларни
        Ўзбекистон Республикасига олиб киришда кўрикдан ўтказиш, ўрганиш ва
        таҳлил қилиш тартиби тўғрисидаги Низом»га мувофиқ, Ариза берувчи минерал
        ўғитлар ва/ёки кимёвий воситаларни иккиламчи кўрикдан ўтказиш учун
        қуйидаги тартибда йиғим суммасини тўлайди:
      </p>
      <table>
        <tbody>
          <tr>
            <td>
              <div>
                <p>Т/р</p>
              </div>
            </td>
            <td>
              <div>
                <p>Номи</p>
              </div>
            </td>
            <td>
              <div>
                <p>Тўлов суммаси ҳар бир юк лот (партия) учун</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <p>1.</p>
              </div>
            </td>
            <td>
              <div>
                <p>Кўрик далолатномаси</p>
              </div>
            </td>
            <td>
              <div>
                <p>Базавий ҳисоблаш миқдорининг бир баравари миқдорида.</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        ​2.2.​Тўлов тартиби: Аризачи аризада кўрсатилган минерал ўғитлар ва/ёки
        кимёвий воситаларни иккиламчи кўрикдан ўтказиш учун
        <a name="_Hlk187683598"></a>
        тўловни шартноманинг 2.1-бандида кўрсатилган миқдордаАгентликнинг
        шартномада кўрсатилган ҳисоб рақамига 100% микдорда олдиндан тўлайди.
      </p>
      <p>
        ​2.3.​Агар тўлов Аризачи томонидан Агентликнинг ҳисоб рақамига олдиндан
        100% тўланмаса, аризачининг аризаси бекор килинади ва уни қабул қилиш
        амалга оширилмаган ҳисобланади.
      </p>
      <p>
        ​2.4. Ариза берувчининг минерал ўғитлар ва/ёки кимёвий воситаларни
        иккиламчи кўрикдан ўтказиш бўйича юборган аризаси Агентлик томонидан уч
        иш куни ичида кўриб чиқилади (юк иккиламчи кўрикдан ўтказилади) ҳамда
        ушбу Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2024 йил 5
        августдаги 481-сонли карори 2-иловаси билан тасдикланган Низомнинг
        3-иловаси шаклига мувофиқ кўрик далолатномаси расмийлаштирилади.
      </p>
      <p>3. Тарафларнинг мажбуриятлари</p>
      <p>
        ​3.1. Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2024 йил 5
        августдаги 481-сонли карори билан тасдикланган «Минерал ўғитларни ва
        ўсимликларни ҳимоя қилиш учун фойдаланиладиган кимёвий воситаларни
        Ўзбекистон Республикасига олиб киришда кўрикдан ўтказиш, ўрганиш ва
        таҳлил қилиш тартиби тўғрисидаги Низом»га мувофиқ Ариза берувчи аризани
        юкни иккиламчи кўрик ўтказиш бўйича хулоса қабул қилинган вақтдан
        бошлаб, бир иш кунида Агентликка такдим килиш мажбуриятини олади.
      </p>
      <p>
        ​3.2. Агентлик томонидан ариза уч иш куни ичида кўриб чиқилади ҳамда
        Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2024 йил 5 августдаги
        481-сонли карори билан тасдикланган «Минерал ўғитларни ва ўсимликларни
        ҳимоя қилиш учун фойдаланиладиган кимёвий воситаларни Ўзбекистон
        Республикасига олиб киришда кўрикдан ўтказиш, ўрганиш ва таҳлил қилиш
        тартиби тўғрисидаги Низом» билан тасдиқланган шаклда кўрик далолатномаси
        расмийлаштириб берилади. Кўрик далолатномаси минерал ўғитлар ва/ёки
        кимёвий воситаларнинг белгиланган талабларга мувофиқлигини баҳолашга
        асос бўлади.
      </p>
      <p>
        3.3. Кўрик далолатномаси қуйидаги ҳолатларда мувофиқлигини баҳолашдан
        ўтказишга нолойиқ (қуйидаги сабаблар кўрсатилган ҳолда) деб топилади:
      </p>
      <p>
        рўйхатга олинган гувоҳномада кўрсатилган қўллаш доираси минерал ўғитлар
        ва/ёки кимёвий воситаларининг ўрам-қути ва ёрлиғидаги матнга мос
        келмаса;
      </p>
      <p>
        талабгор томонидан юкнинг ҳар бир қадоғига QR-код қўйилмаганда
        (QR-кодда- маҳсулот ҳақидаги барча маълумотлар қамраб олиши лозим);
      </p>
      <p>
        импорт қилиниши режалаштирилган маҳсулотни Ўзбекистон Республикасига
        кириб келган даврдан яроқлилик муддати олти ойдан кам бўлганда;
      </p>
      <p>
        лаборатория экспертизаси хулосасида давлат рўйхатига олинганлик
        тўғрисида гувоҳномада белгиланган таркибга номувофиқ деб баҳоланганда.
      </p>
      <p>
        3.4. Мазкур Оферта шартномасининг 3.3-бандида кузатилган ҳолатларнинг
        белгиланган муддатларда бартараф этилиши таъминланмаган тақдирда,
        минерал ўғитлар ва/ёки кимёвий воситаларни Ариза берувчи томонидан
        муддат тугаган кундан бошлаб, 3 иш куни муддатида реэкспорт тартибида
        олиб чиқиб кетиши шарт.
      </p>
      <p>
        3.5. Олиб кириладиган минерал ўғитларни ва/ёки кимёвий воситалар
        QR-кодланган бўлиши талаб этилади. Бунда QR-код ҳаволаси (xml форматида)
        Агентликка тақдим этилади.
      </p>
      <p>
        3.6. Минерал ўғитлар ва/ёки кимёвий воситаларда ўтказилган кўрикда
        QR-код қўйилмаганлиги (ёки тўлиқ қўйилмаганлиги) аниқланганда, Ариза
        берувчига QR-код қўйиш бўйича ўттиз кун муддат берилади.
      </p>
      <p>
        3.7. Ариза берувчи QR-код ҳаволасини Агентликдан олади ва маҳсулотнинг
        ҳар бир қадоғига қўйилишини таъминлайди. Мазкур Оферта шартномасининг
        3.6-банди билан белгиланган ўттиз кун муддат ичида ушбу амалиёт
        бажарилмагани учун олиб киришнинг тўлиқ рад этилишига сабаб бўлади.
      </p>
      <p>
        <a name="_Hlk123288926"></a>4.Коррупцияга қарши қўшимча шартлар
      </p>
      <p>
        4.1.Тарафлар оферта тузишда, офертанинг амал қилиш муддатида ва ушбу
        муддат тугаганидан сўнг, оферта билан боғлиқ коррупциявий ҳаракатлар
        содир қилмасликка келишиб оладилар.
      </p>
      <p>
        4.2.Тарафлар Адлия вазирлиги, Коррупцияга қарши курашиш агентлиги,
        Савдо-саноат палатасининг 2021 йил 12 ноябрдаги 1/4671, 01-19/7284,
        7/01-08-9544-сон қарорига илова билан тасдиқланган “Хўжалик юритувчи
        субъектлар томонидан шартномалар тузишда қўллаш учун тавсивий характерга
        эга бўлган коррупцияга қарши қўшимча шартлар” (кейинги ўринларда -
        коррупцияга қарши қўшимча шартлар)га мувофиқ коррупциянинг олдини олиш
        чораларини тан олади ва уларга риоя этилиши бўйича ҳамкорликни
        таъминлайдилар.
      </p>
      <p>
        4.3.Ҳар бир тараф оферта тузилган пайтда бевосита ўзи ёки унинг ижро
        органлари, мансабдор шахслари ва ходимлари томонидан шартнома билан
        боғлиқ муносабатлар юзасидан қонунга хилоф равишда пул, моддий
        қийматликлар берилмаганлигини, шартнома тузилиши эвазига норасмий пул
        ёки бошқа моддий қийматликлар олинишига йўл қўйилмаганлигини, таклиф
        этилмаганлигини, уларни беришга ваъда қилинмаганлигини, шунингдек моддий
        ёки ҳар қандай турдаги имтиёз, устунликлар олинмаганлигини (келажакда бу
        турдаги ҳаракатларни амалга ошириши мумкинлиги ҳақида таассурот
        қолдирилмаганлигини) кафолатлайди.
      </p>
      <p>
        4.4.Тарафлар, улар томонидан шартнома доирасида жалб қилинган
        шахсларнинг (ёрдамчи пудратчи ташкилотлар, агентлар ва тарафлар назорати
        остидаги бошқа шахсларнинг) юқоридаги ҳаракатларни содир этмаслиги
        юзасидан оқилона чоралар кўради.
      </p>
      <p>
        4.5.Тарафлар уларнинг назорати остида бўлган ва улар номидан ҳаракат
        қиладиган шахслар томонидан коррупцияга қарши белгиланган мажбуриятлар
        бузилганлиги ҳолатлари ҳақида хабар берилганлиги учун уларга тазйиқ
        ўтказилмаслигини кафолатлайдилар.
      </p>
      <p>
        4.6.Агар бир тарафга бошқа тарафнинг коррупцияга қарши қўшимча
        шартларнинг 4 ва 5-бандларида белгиланган мажбуриятларнинг бузилишига
        йўл қўйилганлиги маълум бўлиб қолса, иккинчи тарафни бу ҳақда зудлик
        билан хабардор қилиши ва ушбу тарафдан оқилона муддат ичида тегишли
        чоралар кўрилишини ва амалга оширилган ишлар юзасидан унга хабардор
        қилишини талаб қилиши шарт. Тарафнинг талаби бўйича иккинчи тараф
        томонидан қоидабузарликларни бартараф қилиш бўйича оқилона муддат ичида
        тегишли чоралар кўрилмаган ёки кўриб чиқиш натижалари ҳақида хабардор
        қилмаган тақдирда, ушбу тараф зарарни тўлиқ қоплаб беришни талаб қилишга
        ҳақли.
      </p>
      <p>5.Жавобгарлик, низоларни ҳал этиш тартиби</p>
      <p>
        ​5.1.Тарафлар Ўзбекистон Республикаси қонунчилигига мувофиқ жавобгар
        бўладилар.
      </p>
      <p>
        ​5.2.Шартномани бажариш вақтида форс-мажор ҳолатлари: давлат ҳокимияти
        органларининг ҳаракатлари, ёнғин, сув тошқини, зилзила, бошқа табиий
        офатлар, электр энергиясининг йўқлиги, иш ташлашлар, фуқаролик урушлари,
        тартибсизликлар, шунингдек юқорида санаб ўтилмаган ҳолатлар билан
        чекланмаган ҳар қандай бошқа фавқулодда ҳодисалар юзага келиб, Шартнома
        шартлари бузилса, Агентлик жавобгарликдан озод этилади.
      </p>
      <p>​5.3.Агентлик қуйидагилар учун жавобгар эмас:</p>
      <p>
        ​- Ариза берувчига ўз хизматларидан фойдаланишни таъминлайдиган умумий
        алоқа каналлари ёки хизматларининг сифати учун;
      </p>
      <p>
        ​- Ариза берувчи томонидан “http://singlewindow.uz” сайтида оммавий
        тарзда тақдим этилганмаълумотлар учун.
      </p>
      <p>
        ​5.3.Ариза берувчининг Агентлик томонидан кўрсатилган хизматларга
        эътирози ёки шикоятлари “info@karantin” электрон почтаси орқали кўриб
        чиқиш учун қабул қилинади.
      </p>
      <p>
        ​5.4.Низоли вазиятларни кўриб чиқишда Агентлик ариза берувчидан низога
        доир барча тегишли ҳужжатларни талаб қилишга ҳақли.
      </p>
      <p>
        ​5.5.Тарафлар низо ва келишмовчиликлар юзага келганда, судгача низоларни
        ҳал этиш тартибини қўллаш мажбуриятини оладилар. Агар низони судгача ҳал
        қилишнинг имкони бўлмаса, тарафлар судга мурожаат қилиш ҳуқуқига
        эгадирлар.
      </p>
      <p>6. Алоҳида шартлар</p>
      <p>
        ​6.1.Буюртмачи Шартномани қабул қилиш ва бажариш учун зарур бўлган барча
        хуқуқ ва ваколатларга эга.
      </p>
      <p>
        ​6.2. Лаборатория экспертизаси талаб этиладиган муддат қўлланмаларда
        белгиланади.
      </p>
      <p>
        ​6.3. Кўрик далолатномаси расмийлаштирилгандан сўнг Ариза берувчи
        божхона расмийлаштируви (эркин муамолага чиқариш) учун белгиланган
        тартибда техник жиҳатдан тартибга солиш соҳасидаги норматив ҳужжатлар
        талабларига мувофиқлигини баҳолатиш учун мурожаат қилиши лозим. Кўрик
        далолатномаси божхона расмийлаштирилувига (эркин муамолага чиқариш учун)
        асос бўлмайди.
      </p>
      <p>
        ​​6.4. Агентлик ушбу Шартноманинг исталган шартларини ўзгартириш ёки
        тўлдириш ҳуқуқини ўз веб-сайтида барча ўзгаришларни эълон қилиш орқали
        сақлаб қолади. <br />
        6.5.Ушбу Шартномада назарда тутилмаган барча масалалар Ўзбекистон
        Республикаси қонунчилигимеъёрлари асосида тартибга солинади.
        <a name="bookmark1"></a>
      </p>
      <p>
        ​​6.6. Шартноманинг амал қилиш муддати {dayjs().format("YYYY")} йил 31
        декабрь.
      </p>
    </>
  );
};

export default Korik;

import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Modal, Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalDetail } from "../modal";
import { ModalHoc } from "./hoc";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../../constants/reactQueryKeys";
import {
  MineralInnRefresh,
  applicationCreate,
} from "../../../../apis/guvohnoma";
import { getUser } from "../../../../utils/user";

export const ModalButton = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const queryClient = useQueryClient();

  const config = {
    title: t("close modal"),
    content: t("close modal desc"),
    icon: <ExclamationCircleOutlined />,
    okText: t("close"),
    cancelText: t("lab.back"),
    centered: true,
    onOk: () => {
      setIsModalOpen(false);
    },
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    modal.confirm(config);
  };
  const { user } = getUser();

  const { mutate, isLoading } = useMutation(
    async () => {
      return await MineralInnRefresh({ inn: user?.pin_or_tin });
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [REACT_QUERY_KEYS.MINERAL_APP_LIST],
        });
      },
    }
  );
  return (
    <ModalHoc>
      {data ? (
        <Tooltip placement="topLeft" title={t("edit")}>
          <Button type="primary" onClick={showModal} icon={<EditOutlined />} />
        </Tooltip>
      ) : (
        <Flex style={{ alignItems: "center", gap: "8px" }}>
          <Button
            style={{ boxShadow: "none" }}
            size="large"
            type="primary"
            onClick={showModal}
          >
            {t("inner-fcc.single")}
          </Button>

          <Button
            onClick={mutate}
            size="large"
            loading={isLoading}
            style={{ marginLeft: "8px" }}
            type="default"
          >
            {t("refresh_rekvizit")}
          </Button>
        </Flex>
      )}
      <Modal
        open={isModalOpen}
        centered
        footer={null}
        className="my-modal"
        width={1200}
        destroyOnClose={true}
        onCancel={handleCancel}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        <ModalDetail
          data={data}
          open={isModalOpen}
          onCancel={handleCancel}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
      {contextHolder}
    </ModalHoc>
  );
};

import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  getDistrictsById,
  innerFccCreate,
  innerFccUpdate,
} from "../../../apis/innerFss";
import { getUser } from "../../../utils/user";
import { useEffect, useState } from "react";
import { REACT_QUERY_KEYS } from "../../../constants/reactQueryKeys";

export const UNITS = [
  { value: "166", label: "кг" },
  { value: "168", label: "тн" },
  { value: "796", label: "шт" },
  { value: "055", label: "м2" },
  { value: "113", label: "м3" },
];
export const UNITS_PACKAGING = [
  { value: "954", label: "Пенапласт ящики" },
  { value: "016", label: "Пластмас. ящиков" },
  { value: "952", label: "Полипропилен мешки" },
  { value: "951", label: "Полипропилен сетки" },
  { value: "015", label: "Деревянный ящик" },
  { value: "018", label: "Картон. Коробок" },
  { value: "950", label: "Целлофан пакет" },
  // { value: "916", label: "Упаковка" },
  { value: "953", label: "Сеток" },
  { value: "555", label: "Пачка" },
  { value: "927", label: "Пакет" },
  { value: "012", label: "Мешки" },
  { value: "956", label: "Коробка" },
  { value: "1000", label: "Гофрокартон" },
];

export const useModal = ({ setIsModalOpen, data, getList }) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const { user } = getUser();
  const form = useForm({
    mode: "onChange",
    defaultValues: data
      ? {
          applicant_name: user?.name,
          applicant_tin: user?.pin_or_tin,
          applicant_address: user?.address,
          ...data,
          applicant_phone: ` ${data.applicant_phone}`,
          box_type: data.packaging_company ? 1 : 2,
          productsTwo: [
            ...data?.products,
            { quantity: undefined, unit_code: undefined },
          ],
          productsOne: [
            ...data?.products,
            { quantity: undefined, unit_code: undefined },
          ],
        }
      : {
          applicant_name: user?.name,
          applicant_tin: user?.pin_or_tin,
          applicant_address: user?.address,
          productsTwo: [{ quantity: undefined, unit_code: undefined }],
          productsOne: [{ quantity: undefined, unit_code: undefined }],
        },
  });

  const handleUndefinedFields = (post) => {
    const one = ["quantity", "hs_code", "unit_code", "name"];
    const two = ["quantity", "name_id", "unit_code"];
    const isOne = post.type === 1;
    const data = isOne ? post.productsOne : post.productsTwo;
    const inf = data
      .map((product, index) => {
        const requiredFields = isOne ? one : two;
        const undefinedRequiredFields = requiredFields.filter(
          (field) => product[field] === undefined
        );

        if (undefinedRequiredFields.length === requiredFields.length) {
          return null;
        }

        return undefinedRequiredFields.length > 0
          ? { index, missingFields: undefinedRequiredFields }
          : null;
      })
      .filter((x) => x !== null);
    return inf;
  };
  const { data: manfDistricts } = useQuery({
    queryFn: () => getDistrictsById(form.watch("manufactured_region")),
    queryKey: [
      REACT_QUERY_KEYS.GET_MANDISTRICTS,
      form.watch("manufactured_region"),
    ],
    enabled: !!form.watch("manufactured_region"),
    select: (res) => {
      return res.data?.map((dist) => ({
        ...dist,
        value: dist.soato_code,
        label: dist.name_local,
      }));
    },
  });
  const handleDefinedFieldsWithMissingTransport = (post) => {
    const one =
      post.box_type === 1
        ? [
            "quantity",
            "hs_code",
            "unit_code",
            "name",
            "packaging_quantity",
            "packaging_unit_code",
            "calibration",
          ]
        : ["quantity", "hs_code", "unit_code", "name"];
    const two = ["quantity", "name_id", "unit_code"];
    const isOne = post.type === 1;
    const data = isOne ? post.productsOne : post.productsTwo;
    const inf = data
      .map((product, index) => {
        const requiredFields = isOne ? one : two;
        const definedRequiredFields = requiredFields.filter(
          (field) => product[field] !== undefined
        );
        if (definedRequiredFields.length === requiredFields.length) {
          if (!product.transport_number && product.transport_number !== "") {
            return { index, definedFields: definedRequiredFields };
          }
        }

        return null;
      })
      .filter((x) => x !== null);
    return inf;
  };

  const handleUndefinedTransports = (post) => {
    const isOne = post.type === 1;
    const data = isOne ? post.productsOne : post.productsTwo;
    const transport = data
      ?.map((pr, i) => {
        if (!pr?.transport_number && pr?.transport_number !== "") {
          return i;
        }
        return null;
      })
      .filter((x) => x !== null);
    return transport;
  };

  const onSubmit = (post) => {
    setIsSubmited(true);
    // if (post.applicant_phone?.includes("_")?.length > 1) {
    //   form.setError("applicant_phone", {
    //     type: "custom",
    //     message: "inner-fcc.error",
    //   });
    //   form.setFocus("applicant_phone");
    // } else {
    //   const isOne = post.type === 1;
    //   const fieldName = isOne ? "productsOne" : "productsTwo";
    //   const requiredIndexes = handleUndefinedFields(post);
    //   const data = isOne ? post?.productsOne : post?.productsTwo;
    //   const notTransports = handleUndefinedTransports(post);
    //   const definedFields = handleDefinedFieldsWithMissingTransport(post);
    //   if (
    //     requiredIndexes?.length > 0 ||
    //     (definedFields?.length > 0 && notTransports?.length > 0)
    //   ) {
    //     requiredIndexes?.forEach((indexes) => {
    //       indexes.missingFields.forEach((field) => {
    //         form.setError(`${fieldName}.${indexes.index}.${field}`, {
    //           type: "custom",
    //           message: "inner-fcc.error",
    //         });
    //       });
    //     });

    //     notTransports?.forEach((indexes) => {
    //       form.setError(`${fieldName}.${indexes}.transport_number`, {
    //         type: "custom",
    //         message: "inner-fcc.error",
    //       });
    //     });
    //   } else {
    //     const products = data
    //       ?.map((p) => ({
    //         name_id: post.type === 3 ? p?.name_id : undefined,
    //         name: post.type === 3 ? p?.product_data?.label : p?.name,
    //         hs_code: post.type === 3 ? p?.product_data?.code : p.hs_code,
    //         quantity: p.quantity,
    //         unit_code: p.unit_code,
    //         unit_name: UNITS.find((u) => u.value === p.unit_code)?.label,
    //         transport_number: p?.transport_number?.toUpperCase(),
    //         packaging_quantity: p.packaging_quantity,
    //         packaging_unit_name: UNITS_PACKAGING.find(
    //           (u) => u.value === p.packaging_unit_code
    //         )?.label,
    //         packaging_unit_code: p.packaging_unit_code,
    //         calibration: p.calibration ? Number(p.calibration) : undefined,
    //       }))
    //       .filter((product) =>
    //         Object.values(product).some((value) => value !== undefined)
    //       )
    //       ?.filter((product) => product.calibration !== null);
    //     const { productsOne, productsTwo, ...res } = post;
    //     const requestData = {
    //       ...res,
    //       status: 1,
    //       products,
    //       packaging_company_contract_file:
    //         post.packaging_company_contract_file?.id,
    //       request_number: post?.request_number ?? null,
    //       request_date: post?.request_date ?? null,
    //       district_in_b_point:
    //         post.type === 3 ? post.district_in_b_point : null,
    //       bazar_in_b_point: post.type === 3 ? post.bazar_in_b_point : null,
    //       fea_point_in_b_point:
    //         post.type === 1 ? post.fea_point_in_b_point : null,
    //       applicant_phone: post?.applicant_phone
    //         ?.replaceAll(" ", "")
    //         .slice(4, 13),
    //     };
    //     setIsSubmited(true);
    //     console.log(requestData);
    //     // mutate(requestData);
    //   }
    // }
  };
  const onSubmitData = (post) => {
    if (post.applicant_phone?.includes("_")?.length > 1) {
      form.setError("applicant_phone", {
        type: "custom",
        message: "inner-fcc.error",
      });
      form.setFocus("applicant_phone");
      setIsSubmited(false);
    } else {
      const isOne = post.type === 1;
      const fieldName = isOne ? "productsOne" : "productsTwo";
      const requiredIndexes = handleUndefinedFields(post);
      const data = isOne ? post?.productsOne : post?.productsTwo;
      const notTransports = handleUndefinedTransports(post);
      const definedFields = handleDefinedFieldsWithMissingTransport(post);

      if (
        requiredIndexes?.length > 0 ||
        (definedFields?.length > 0 && notTransports?.length > 0)
      ) {
        requiredIndexes?.forEach((indexes) => {
          indexes.missingFields.forEach((field) => {
            form.setError(`${fieldName}.${indexes.index}.${field}`, {
              type: "custom",
              message: "inner-fcc.error",
            });
          });
        });

        notTransports?.forEach((indexes) => {
          form.setError(`${fieldName}.${indexes}.transport_number`, {
            type: "custom",
            message: "inner-fcc.error",
          });
        });
        setIsSubmited(false);
      } else {
        const products = data
          ?.map((p) => ({
            name_id: post.type === 3 ? p?.name_id : undefined,
            name: post.type === 3 ? p?.product_data?.label : p?.name,
            hs_code: post.type === 3 ? p?.product_data?.code : p.hs_code,
            quantity: p.quantity,
            unit_code: p.unit_code,
            unit_name: UNITS.find((u) => u.value === p.unit_code)?.label,
            transport_number: p?.transport_number?.toUpperCase(),
            packaging_quantity: p.packaging_quantity,
            packaging_unit_name: UNITS_PACKAGING.find(
              (u) => u.value === p.packaging_unit_code
            )?.label,
            packaging_unit_code: p.packaging_unit_code,
            calibration: p.calibration ? Number(p.calibration) : undefined,
            manufactured_district_soato_code:
              p?.manufactured_district_soato_code,
            manufactured_district_name: manfDistricts?.find(
              (a) => p?.manufactured_district_soato_code === a.value
            )?.label,
            manufactured_place: p?.manufactured_place,
          }))
          .filter((product) =>
            Object.values(product).some((value) => value !== undefined)
          )
          ?.filter((product) => product.calibration !== null);
        const { productsOne, productsTwo, ...res } = post;
        const requestData = {
          ...res,
          status: 1,
          products,
          packaging_company_contract_file:
            post.packaging_company_contract_file?.id,
          request_number: post?.request_number ?? null,
          request_date: post?.request_date ?? null,
          district_in_b_point:
            post.type === 3 ? post.district_in_b_point : null,
          bazar_in_b_point: post.type === 3 ? post.bazar_in_b_point : null,
          fea_point_in_b_point:
            post.type === 1 ? post.fea_point_in_b_point : null,
          applicant_phone: post?.applicant_phone
            ?.replaceAll(" ", "")
            .slice(4, 13),
        };

        mutate(requestData);
      }
    }
  };
  const { mutate, isLoading } = useMutation(
    async (info) =>
      data ? await innerFccUpdate(info) : await innerFccCreate(info),
    {
      onSuccess: (res) => {
        setIsFinished(true);
        setIsSubmited(false);
        getList();
        setIsModalOpen(false);
        toast.info(res.data.comment);
      },
      onError: (error) => {
        setIsFinished(false);
        setIsSubmited(false);
        toast.error(
          error?.response?.data?.detail ?? error?.response?.data ?? "Error"
        );
      },
    }
  );
  function handleClick() {
    const post = form.getValues();
    const { productsOne, productsTwo } = post;
    if (productsOne?.length > 3 || productsTwo?.length > 3) {
      setIsSubmited(true);
    }
  }

  useEffect(() => {
    if (isSubmited) {
      if (!isFinished) {
        const data = form.getValues();
        onSubmitData(data);
      }
    }
  }, [isSubmited, isFinished]);
  return {
    onSubmit,
    isLoading,
    form,
    handleClick,
    isSubmited,
  };
};

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Col, Modal, Row, Space, Typography } from "antd";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { Forms } from "../forms";
import { useModal } from "../useModal";
import "./style.css";
const { Title } = Typography;

const videoConfig = {
  url: "https://youtu.be/8GG6yaFDzMI",
  controls: true,
  width: "100%",
  height: "350px",
  style: {
    borderRadius: "10px",
    overflow: "hidden",
    width: "100%",
    backgroundColor: "#f5f5f5",
  },
};
export const ModalDetail = ({
  isModalOpen,
  setIsModalOpen,
  data,
  getList,
  ...props
}) => {
  const { t } = useTranslation();

  const { onSubmit, isLoading, form, isSubmited, handleClick } = useModal({
    setIsModalOpen,
    isModalOpen,
    data,
    getList,
  });
  const { handleSubmit } = form;

  const [modal, contextHolder] = Modal.useModal();

  const config = {
    content: (
      <div
        style={{
          paddingTop: "8px",
          width: "100%",
          flexDirection: "column",
          display: "flex",
          gap: "16px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "16px",
            alignItems: "flex-start",
          }}
        >
          <ExclamationCircleFilled
            style={{
              color: "orange",
              fontSize: "32px",
              marginTop: "8px",
              marginLeft: "8px",
            }}
          />
          <div>
            <h2 style={{ marginBottom: 2 }}>{t("alert")}</h2>
            <p style={{ marginBottom: 0, color: "#777", fontSize: "16px" }}>
              {t("alert message")}
            </p>
          </div>
        </div>
        <div>
          <ReactPlayer {...videoConfig} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "16px",
            alignItems: "flex-center",
            justifyContent: "space-between",
          }}
        >
          <p style={{ color: "#777" }}>{t("alert confirm")}</p>
        </div>
      </div>
    ),
    width: "600px",
    icon: null,
    centered: true,
    cancelButtonProps: { style: { display: "none" } },
    okText: t("lab.continue"),
    onOk: () => {
      localStorage.setItem("fcc-popup", true);
    },
  };

  useEffect(() => {
    if (!localStorage.getItem("fcc-popup")) {
      // modal.info(config);
      localStorage.setItem("fcc-popup", true);
    }
  }, []);
  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Title level={3}>{t("top.inner-fcc")}</Title>
              {contextHolder}
            </Col>

            <Col
              span={24}
              className="main-content"
              style={{ paddingBottom: "2px" }}
            >
              <Forms isEdit={!!data} />
            </Col>

            <Col span={24}>
              <Space
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                <Button
                  size="large"
                  style={{ boxShadow: "none", minWidth: "200px" }}
                  onClick={props.onCancel}
                >
                  {t("inner-fcc.cancel")}
                </Button>
                <Button
                  style={{ boxShadow: "none", minWidth: "200px" }}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading || isSubmited}
                  onClick={() => handleClick()}
                >
                  {t("inner-fcc.send")}
                </Button>
              </Space>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </>
  );
};

import { Collapse } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./instruction.css";
import queryString from "query-string";

const Instruction = (props) => {
  const lang = useSelector((state) => state.language.lang);
  const openedKey = new URLSearchParams(window.location.search).get("key");

  const { Panel } = Collapse;
  const { t } = useTranslation();

  useEffect(() => {
    // let value = new URLSearchParams(window.location.search);
    // value.delete("key");
    props.history.push({ search: queryString.stringify({}) });
  }, []);
  return (
    <div className="instruction-page">
      <h1>{t("navbar.ins")}</h1>
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={openedKey ? [`${openedKey}`] : ["0"]}
      >
        <Panel header={`1. ${t("instruction.inst1")}`} key="1">
          {/* <p>
            O'zbekiston Respublikasi O'simliklar Karantini va Himoyasi Agentligi
            tomonidan tadbirkorlar uchun joriy etilgan kabinet haqida qisqacha
            va ichki FSS uchun ariza berishni to'laqonli aks ettirilgan
            videqo'llanma.
          </p> */}
          <iframe
            src="https://www.youtube.com/embed/XJxrZ9VeiXs"
            title="O'simliklar karantini va himoyasi agentligi tomonidan tadbirkorlar uchun ishlab chiqilgan kabinet"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Panel>

        <Panel header={`2. ${t("instruction.entity")}`} key="2">
          {lang === "uz" ? (
            <iframe
              src="https://www.youtube.com/embed/p2fCKtlfY_o"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          ) : (
            <iframe
              src="https://www.youtube.com/embed/8LePKxZZlKI"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </Panel>

        <Panel header={`3. ${t("instruction.inst3")}`} key="3">
          <iframe
            src="https://www.youtube.com/embed/9H8COPMV5Dg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Panel>

        <Panel header={`4. ${t("instruction.inst4")}`} key="4">
          {
            <iframe
              src="https://www.youtube.com/embed/-fd9HhEit6U"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          }
        </Panel>

        <Panel header={`5. ${t("instruction.inst5")}`} key="5">
          {
            <iframe
              src="https://www.youtube.com/embed/CYBTGOTc9-Y"
              title="Guvohnomaga ariza berish bo’yicha video yo’riqnoma"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          }
        </Panel>
        <Panel header={`6. ${t("get_licenc")}`} key="6">
          {
            <iframe
              src="https://www.youtube.com/embed/iiESny0C70o?si=1p8YY38kxNQQ59wc"
              title="Litsenziya olish uchun ariza berish tartibi"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          }
        </Panel>
      </Collapse>
    </div>
  );
};

export default Instruction;

import { Button, Flex, Modal } from "antd";
import { useEffect, useState } from "react";
import WarningIcon from "../../../../assets/icons/warningIcon.svg";
import { useTranslation } from "react-i18next";

export const ModalComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    sessionStorage.setItem("isCloseModalSession", "true");
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    sessionStorage.setItem("isCloseModalSession", "true");
    setIsModalOpen(false);
  };
  useEffect(() => {
    const isCloseModalSession = sessionStorage.getItem("isCloseModalSession");
    if (!isCloseModalSession) {
      showModal();
    }
  }, []);

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      width="780px"
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <h1 style={{ textAlign: "center" }} className="warning">
        <img src={WarningIcon} />
      </h1>

      <div className="modal-inner warning">
        <div className="warning-wrap common">
          <p style={{ fontWeight: "bold" }}>{t("warning.text_guvohnoma_2")}</p>
          <p>{t("warning.text_guvohnoma_2_1")}</p>
        </div>
      </div>
      <div className="modal-inner warning">
        <div className="warning-wrap common">
          <p>{t("warning.text_guvohnoma_1")}</p>
        </div>
      </div>

      <Flex style={{ width: "100%", justifyContent: "center" }}>
        <Button
          danger
          type="primary"
          size="large"
          style={{ backgroundColor: "#d71b1b" }}
          onClick={handleOk}
        >
          {t("common.read")}
        </Button>
      </Flex>
    </Modal>
  );
};

import React from "react";

const AnnouncementBanner = () => {
  return (
    <div
      style={{
        backgroundColor: "#FFEB3B",
        padding: "8px 0 7px",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <marquee
        behavior="scroll"
        direction="left"
        style={{
          color: "#333",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        O‘simliklarni himoya qilish vositalarini ro‘yxatga olish to‘g‘risidagi
        arizalarni qabul qilish 2025-yilning 1-fevralida yakunlanadi. | Прием
        заявок на регистрацию средств защиты растений завершится 1 февраля 2025
        года.
      </marquee>
    </div>
  );
};

export default AnnouncementBanner;

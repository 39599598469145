import { Button, Col, Flex, Row, Space, Typography } from "antd";
import React from "react";
import { useFormContext } from "react-hook-form";
import * as XLSX from "xlsx";
import { ReactComponent as IconExcel } from "../../../../assets/icons/excel.svg";
import { FormFileUploadExcel } from "../../../../components/formComponents/uploadExcel/upload";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { UNITS, UNITS_PACKAGING } from "../useModal";
const { Title } = Typography;

export const ExcelModalDetail = ({ handleCancel }) => {
  const { t } = useTranslation();
  const form = useFormContext();

  function CompareUnit(unit_code, index) {
    if (UNITS?.map((u) => u.value)?.includes(unit_code)) {
      return unit_code;
    } else {
      form.setError(`productsOne.${index}.packaging_unit_code`, {
        type: "manual",
        message: "unit err",
      });
      return undefined;
    }
  }
  function CompareUnitComp(unit_code, index) {
    if (UNITS_PACKAGING?.map((u) => u.value)?.includes(unit_code)) {
      return unit_code;
    } else {
      form.setError(`productsOne.${index}.unit_code`, {
        type: "manual",
        message: "unit err",
      });
      return undefined;
    }
  }
  const props = {
    control: form.control,
    rules: {
      required: {
        value: true,
        message: "inner-fcc.error",
      },
    },
  };

  const onSubmit = () => {
    const file = form.getValues("file")?.file;
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 2 });
      const data = jsonData;
      const fields = data?.map((info, i) => ({
        name: info["Mahsulot nomi\r\n(majburiy)"],
        hs_code: info["TIF TN kodi\r\n(majburiy)"]?.replaceAll(" ", ""),
        quantity: info["Mahsulotning miqdori (og'irligi)\r\n(majburiy)"],
        calibration: info["Kalibrofka"],
        packaging_quantity: info["Qadoq soni"],
        packaging_unit_code: CompareUnitComp(info["Qadoq o'lchov birligi"]),
        unit_code: CompareUnit(
          info["Mahsulotning aniq vazn birligi kodi\r\n(majburiy)"],
          i
        ),
        manufactured_district_soato_code:
          info["Mahsulot yetishtirilgan tuman kodi (majburiy)"],
        manufactured_place: info["Mahsulot yetishtirilgan joy (majburiy)"],
        transport_number: info["Avtomobil raqami\r\n(majburiy)"],
      }));

      const isValid = fields.every((field) => {
        const undefinedCount = (
          form.watch("box_type") === 1
            ? [
                field.name,
                field.hs_code,
                field.quantity,
                field.unit_code,
                field.transport_number,
                field.packaging_unit_code,
                field.packaging_quantity,
                field.calibration,
                field.manufactured_district_soato_code,
                field.manufactured_place,
              ]
            : [
                field.name,
                field.hs_code,
                field.quantity,
                field.unit_code,
                field.transport_number,
                field.manufactured_district_soato_code,
                field.manufactured_place,
              ]
        ).filter((value) => value === undefined).length;
        return undefinedCount < 2;
      });

      if (isValid) {
        console.log(fields);
        form.setValue("productsOne", [
          ...fields,
          {
            quantity: undefined,
            unit_code: undefined,
          },
        ]);
        handleCancel();
        form.setValue("file", undefined);
      } else {
        toast.error("Ma'lumotlar xato");
      }
    };
    reader.readAsBinaryString(file);
  };

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Title style={{ marginBottom: 0 }} level={3}>
          {t("excel.title")}
        </Title>
      </Col>
      <Col span={24}>
        <FormFileUploadExcel {...props} name="file" />
      </Col>
      <Col span={24}>
        <div
          style={{
            padding: "22px 24px",
            borderRadius: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "rgb(243, 245, 247)",
          }}
        >
          <Flex align="center" gap="8px" justify="space-between">
            <div style={{ maxWidth: "calc(100% - 140px)" }}>
              <Flex align="center" gap="8px">
                <IconExcel />{" "}
                <Title
                  style={{ margin: 0, fontSize: "16px", fontWeight: 500 }}
                  level={4}
                >
                  {t("excel.sample")}
                </Title>
              </Flex>
              <p style={{ paddingTop: "10px", color: "#8393A6", margin: 0 }}>
                {t("excel.sample desc")}
              </p>
            </div>
            <Button href="/IchkiFSSMahsulotlariShabloni.xlsx">
              {t("invoice.download")}
            </Button>
          </Flex>
        </div>
      </Col>
      <Col span={24}>
        <Space
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ boxShadow: "none", minWidth: "200px" }}
            size="large"
            type="primary"
            htmlType="submit"
            onClick={onSubmit}
          >
            {t("lab.continue")}
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

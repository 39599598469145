import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import request from "../../helpers/createRequest";
import { links } from "../../redux/actions/linksAction";
import { userLogout } from "../../redux/actions/loginAction";
import "./create.css";
import Akd from "./docs/Akd";
import Cert from "./docs/Cert";
import Fss from "./docs/Fss";
import Fum from "./docs/Fum";
import Kr from "./docs/Kr";
import Lab from "./docs/Lab";
import { SprutDoc } from "./docs/sprut";
import Korik from "./docs/korik";
import Mineral from "./docs/mineral";

const AddInvoice = ({ openAdd, setOpenAdd, serviceArrs, detail }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isBtn, setIsBtn] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);

  const { pinOrTin } = useSelector((state) => state.login);
  const [allRegions, setAllRegions] = useState([]);

  const [applicant, setApplicant] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [region, setRegion] = useState("");

  const changeLink = (name) => {
    dispatch(links(name));
  };

  useEffect(() => {
    if (pinOrTin.length > 9) {
      setUser(1);
    } else {
      setUser(2);
    }
    getRegions();
  }, []);

  useEffect(() => {
    if (applicant && serviceType) {
      setIsBtn(true);
    }
  }, [applicant, serviceType]);

  const getRegions = () => {
    request
      .get(`/api/regions/`)
      .then((res) => {
        setAllRegions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRefreshDetails = () => {
    request
      .post("/applicant/detail/")
      .then((res) => {
        toast.warning(t("invoice.wait"));
        setOpenAdd(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleApplicantChange = (applicant) => {
    setApplicant(applicant);
    if (applicant == 2) {
      if (applicant != user) {
        setError(true);
        return;
      }
    }
    setError(false);
  };

  const handleServiceChange = (service) => {
    setRegion("");
    setServiceType(service);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (error) {
      toast.error("Tizimga yuridik shaxs sifatida kiring!");
    }
    if (isAgree) {
      request
        .post("/applicant/invoice/list/", {
          service_type: Number(serviceType),
          region_pk: Number(region) || 15,
        })
        .then((res) => {
          console.log(res);
          toast.success(res.data.comment);
          setServiceType("");
          setApplicant("");
          setRegion("");
          setIsAgree(false);
          setIsBtn(false);
          setOpenAdd(false);
        })
        .catch((e) => {
          console.log(e);
          toast.error("Xatolik!");
        });
    } else {
      toast.warning("Shartlarga rozilik bildiring!");
    }
  };

  const logout = () => {
    dispatch(userLogout());
  };

  return (
    <Modal
      open={openAdd}
      onOk={() => setOpenAdd(false)}
      onCancel={() => setOpenAdd(false)}
      centered
      title={null}
      footer={null}
      closeIcon={<MdClose className="icon" />}
      width={1060}
      className="add-fcc-modal"
    >
      <h1>{t("invoice.contract-of")}</h1>
      <div className="modal-inner">
        {user == 1 && (
          <div className="warning-wrap">
            <p>
              {t("invoice.entity1")}
              <span
                style={{ color: "#1677ff", cursor: "pointer" }}
                onClick={logout}
              >
                {t("invoice.entity2")}
              </span>
              {t("invoice.entity3")}
              <Link to={"/instruction?key=2"}>
                <span onClick={() => changeLink("/instruction")}>
                  {t("invoice.entity4")}
                </span>
              </Link>
            </p>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="roow">
            <div className="form-group">
              <label>{t("invoice.applicant")}</label>
              <select
                value={applicant}
                onChange={(e) => handleApplicantChange(e.target.value)}
                className={error ? "error-select" : ""}
                required
              >
                <option value="">{t("inner-fcc.tanla")}</option>
                <option value="1">{t("invoice.company")}</option>
                <option value="2">{t("invoice.private")}</option>
                {/* {allRegions?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name_local}
                    </option>
                  );
                })} */}
              </select>
              {error && (
                <label className="error-mes">{t("invoice.entityError")}</label>
              )}
            </div>
          </div>
          <div className="roow">
            <div className="form-group">
              <label>{t("invoice.type-invoice")}</label>
              <select
                value={serviceType}
                onChange={(e) => handleServiceChange(e.target.value)}
                required
              >
                <option value="">{t("inner-fcc.tanla")}</option>

                {serviceArrs?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {serviceType == 3 && (
            <div className="roow">
              <div className="form-group">
                <label>{t("inner-fcc.reg-za")}</label>
                <select
                  required
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                >
                  <option value="">{t("inner-fcc.tanla")}</option>
                  {allRegions?.map((item1, index1) => {
                    return (
                      <option key={index1} value={item1.id}>
                        {item1.name_local}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
          <div className="btn-wrap">
            <button
              type="submit"
              className={isAgree && !error && isBtn ? "button-active" : ""}
              disabled={error}
            >
              {t("invoice.confirm-inv")}
            </button>
          </div>
        </form>

        <div className="condition-wrap">
          <div className="outer-condition" id="terms">
            <div className="inner-condition" id="inner-terms">
              {serviceType == 1 && <Kr />}
              {serviceType == 2 && <Akd />}
              {serviceType == 3 && <Fum />}
              {serviceType == 4 && <Fss />}
              {serviceType == 5 && <Fss />}
              {serviceType == 6 && <Lab />}
              {serviceType == 8 && <SprutDoc />}
              {serviceType == 11 && <Mineral />}
              {serviceType == 12 && <Korik />}
            </div>
            {serviceType && (
              <div className="agree-wrap">
                <div className="agree-check">
                  <label className="checkbox-wrap">
                    <input
                      type="checkbox"
                      checked={isAgree}
                      onChange={(e) => setIsAgree(e.target.checked)}
                    />
                    {/* <span className="check-effect"></span> */}
                    <span>{t("invoice.agree")}</span>
                  </label>
                </div>
              </div>
            )}
          </div>
          {/* {trackScrolling() && "ss"} */}
        </div>

        <div className="address-wrap">
          <h3>{t("invoice.addresses")}</h3>

          <div>
            {detail && (
              <table>
                <tbody>
                  <tr>
                    <td className="text-right">
                      {t("oferta.organization name")}:
                    </td>
                    <td className="text-left">{detail?.name || "--"}</td>
                  </tr>
                  <tr>
                    <td className="text-right">{t("oferta.address")}:</td>
                    <td className="text-left">
                      {detail?.customer_address || "--"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">{t("oferta.phone")}:</td>
                    <td className="text-left">
                      {detail?.customer_phone || "--"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">{t("oferta.hr")}:</td>
                    <td className="text-left">
                      {detail?.customer_bank_account || "--"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">{t("oferta.inn")}:</td>
                    <td className="text-left">{detail?.tin || "--"}</td>
                  </tr>
                  <tr>
                    <td className="text-right">{t("oferta.oked")}:</td>
                    <td className="text-left">
                      {detail?.customer_oked || "--"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">{t("oferta.bank")}:</td>
                    <td className="text-left">
                      {detail?.customer_bank_name || "--"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">{t("oferta.mfo")}:</td>
                    <td className="text-left">
                      {detail?.customer_mfo || "--"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">{t("oferta.director")}:</td>
                    <td className="text-left">
                      {detail?.customer_director || "--"}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div className="refresh-wrap">
                        <span onClick={getRefreshDetails}>
                          {t("invoice.refresh")}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddInvoice;

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { FormInput } from "../../../../components/formComponents/input";
import { FormSelect } from "../../../../components/formComponents/select";
import { useMyForm } from "../forms/usemyForm";
import { UNITS } from "../useModal";
import { AddButton } from "./components/addButton";
import { ProductSelect } from "./components/product-select";
import { useQuery } from "@tanstack/react-query";
import { getDistrictsById } from "../../../../apis/innerFss";
import { REACT_QUERY_KEYS } from "../../../../constants/reactQueryKeys";

export const Two = () => {
  const { t, props, watch, form } = useMyForm();
  const { data: manfDistricts, isFetching: manDistrictsLoading } = useQuery({
    queryFn: () => getDistrictsById(watch("manufactured_region")),
    queryKey: [REACT_QUERY_KEYS.GET_MANDISTRICTS, watch("manufactured_region")],
    enabled: !!watch("manufactured_region"),
    select: (res) => {
      return res.data?.map((dist) => ({
        ...dist,
        value: dist.soato_code,
        label: dist.name_local,
      }));
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: "productsTwo",
  });

  return (
    <>
      {fields.map((field, index) => (
        <Col key={field.id} span={24}>
          <Row gutter={[16, 16]} align="bottom">
            <Col span={24} md={6}>
              <ProductSelect
                fieldName="productsTwo"
                disabled={fields?.length - 1 > index}
                rul={
                  fields?.length - 1 > index || index === 0
                    ? { ...props }
                    : { ...props.control }
                }
                ind={index}
              />
            </Col>
            <Col span={24} md={6} key={field.id}>
              <FormInput
                control={props.control}
                rules={
                  fields?.length - 1 > index || index === 0
                    ? { ...props.rules }
                    : undefined
                }
                disabled={fields?.length - 1 > index}
                type="number"
                label={t("inner-fcc.quan")}
                name={`productsTwo.${index}.quantity`}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
              />
            </Col>
            <Col span={24} md={6}>
              <FormSelect
                control={props.control}
                rules={
                  fields?.length - 1 > index || index === 0
                    ? { ...props.rules }
                    : undefined
                }
                disabled={fields?.length - 1 > index}
                label={t("unit")}
                placeholder={t("inner-fcc.tanla")}
                name={`productsTwo.${index}.unit_code`}
                options={UNITS}
              />
            </Col>
            <Col span={24} md={6}>
              <FormSelect
                rules={
                  fields?.length - 1 > index || index === 0
                    ? { ...props.rules }
                    : undefined
                }
                disabled={fields?.length - 1 > index}
                placeholder={t("inner-fcc.tanla")}
                label={t("inner-fcc.ray-pro")}
                name={`productsTwo.${index}.manufactured_district_soato_code`}
                options={manfDistricts}
                loading={manDistrictsLoading}
              />
            </Col>

            <Col span={24} md={6}>
              <FormInput
                rules={
                  fields?.length - 1 > index || index === 0
                    ? { ...props.rules }
                    : undefined
                }
                disabled={fields?.length - 1 > index}
                label={t("inner-fcc.place")}
                placeholder={t("inner-fcc.place") + ", MFY yoki F/X"}
                name={`productsTwo.${index}.manufactured_place`}
                maxLength={128}
              />
            </Col>

            <Col span={24} md={6} style={{ textAlign: "right" }}>
              {fields?.length - 1 > index ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "16px",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "calc(100% - 64px)" }}>
                    <FormInput
                      style={{
                        textTransform: "uppercase",
                      }}
                      disabled={true}
                      label={t("lab.extra-info")}
                      placeholder="-"
                      name={`productsTwo.${index}.transport_number`}
                    />
                  </div>
                  <Tooltip placement="topRight" title={t("lab.delete")}>
                    <Button
                      onClick={() => remove(index)}
                      type="text"
                      danger
                      ghost
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </div>
              ) : (
                <AddButton
                  append={append}
                  index={index}
                  fieldName="productsTwo"
                />
              )}
            </Col>
          </Row>
        </Col>
      ))}
    </>
  );
};
